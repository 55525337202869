/*
 * @FileDescription:
 * @Author: jianguo Wang
 * @Date: 2023-12-14 15:29:31
 * @LastEditors: jianguo Wang
 * @LastEditTime: 2023-12-28 09:21:56
 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

Vue.use(ElementUI);
Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
  // chrome
  document.body.scrollTop = 0;
  // firefox
  document.documentElement.scrollTop = 0;
  // safari
  window.pageYOffset = 0;
  next();
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
