<!--
 * @FileDescription: 
 * @Author: jianguo Wang
 * @Date: 2023-12-15 16:46:56
 * @LastEditors: jianguo Wang
 * @LastEditTime: 2023-12-28 15:46:24
-->
<template>
  <div>
    <headerPart />
    <firstPart />
    <secondPart />
    <thirdPart />
    <footerPart />
  </div>
</template>

<script>
import headerPart from "../components/header.vue";
import firstPart from "../components/firstPart.vue";
import secondPart from "../components/secondPart.vue";
import thirdPart from "../components/thirdPart.vue";
import footerPart from "../components/footer.vue";

export default {
  name: "App",
  components: {
    headerPart,
    firstPart,
    secondPart,
    thirdPart,
    footerPart,
  },
  methods: {},
};
</script>

<style></style>
