<!--
 * @FileDescription: 
 * @Author: jianguo Wang
 * @Date: 2023-12-14 15:29:31
 * @LastEditors: jianguo Wang
 * @LastEditTime: 2024-01-18 09:58:24
-->
<template>
  <div class="page-contain">
    <div class="step-contain">
      <div class="step-box">
        <div class="step step01">01</div>
        <div class="step-title">申请注册</div>
        <div class="step-tip">只需要一个邮箱即可轻松注册</div>
      </div>
      <div class="step-box">
        <div class="step step02">02</div>
        <div class="step-title">身份认证</div>
        <div class="step-tip">下载APP之后，按照指示完成流程 只需2分钟系统自动完成</div>
      </div>
      <div class="step-box">
        <div class="step step03">03</div>
        <div class="step-title">放心使用</div>
        <div class="step-tip">获取卡片后，充值，消费APP可以详细查看每一笔记录让你随时掌握，管理您的财务状况</div>
      </div>
    </div>

    <!-- pc -->
    <div class="pc-contain">
      <div class="contain">
        <div class="tip-contain">
          <div class="tip1">
            便捷存储 <br />
            管理您的资金和加密货币
          </div>
          <div class="tip2">通过我们的应用程序管理您的资产，并随时使用您的卡片付款；支持多种资金以及加密货币类型，方便您的日常消费</div>
        </div>
        <img src="@/assets/01@3x.png" alt="" />
      </div>
      <div class="contain">
        <img src="@/assets/02@3x.png" alt="" />
        <div class="tip-contain">
          <div class="tip1">使用加密货币支付</div>
          <div class="tip2">用加密货币购买 CregisPay 预付卡 使用安全的虚拟卡在数百万在线商家处付款</div>
        </div>
      </div>
      <div class="contain">
        <div class="tip-contain">
          <div class="tip1">什么是虚拟卡？</div>
          <div class="tip2">
            虚拟卡是一种临时数字支付卡，可用于在线购物 CregisPay是可以充值加密货币的虚拟卡 当您购买卡时，加密货币就会转换为美元，并且资金可以立即用于您想要的地方
          </div>
        </div>
        <img src="@/assets/03@3x.png" alt="" />
      </div>
      <div class="contain">
        <img src="@/assets/04@3x.png" alt="" />
        <div class="tip-contain">
          <div class="tip1">消费无界限，开拓新市场</div>
          <div class="tip2">
            通过卡计划扩展您的业务，该计划可以接收数字资产和法定货币，没有任何支出限制；将您的卡程序带到任何您想要的地方。利用我们的影响力，将您的势力范围扩展到地区、国家和时区之外
          </div>
        </div>
      </div>
    </div>

    <!-- 移动端 -->
    <div class="phone-contain">
      <div class="contain">
        <div class="tip-contain">
          <div class="tip1">便捷存储 管理您的资金和加密货币</div>
          <div class="tip2">通过我们的应用程序管理您的资产，并随时使用您的卡片付款；支持多种资金以及加密货币类型，方便您的日常消费</div>
        </div>
        <img src="@/assets/01@3x.png" alt="" />
      </div>
      <div class="contain">
        <div class="tip-contain">
          <div class="tip1">使用加密货币支付</div>
          <div class="tip2">用加密货币购买 CregisPay 预付卡 使用安全的虚拟卡在数百万在线商家处付款</div>
        </div>
        <img src="@/assets/02@3x.png" alt="" />
      </div>
      <div class="contain">
        <div class="tip-contain">
          <div class="tip1">什么是虚拟卡？</div>
          <div class="tip2">
            虚拟卡是一种临时数字支付卡，可用于在线购物 CregisPay是可以充值加密货币的虚拟卡 当您购买卡时，加密货币就会转换为美元，并且资金可以立即用于您想要的地方
          </div>
        </div>
        <img src="@/assets/03@3x.png" alt="" />
      </div>
      <div class="contain">
        <div class="tip-contain">
          <div class="tip1">消费无界限，开拓新市场</div>
          <div class="tip2">
            通过卡计划扩展您的业务，该计划可以接收数字资产和法定货币，没有任何支出限制；将您的卡程序带到任何您想要的地方。利用我们的影响力，将您的势力范围扩展到地区、国家和时区之外
          </div>
        </div>
        <img src="@/assets/04@3x.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "secondPart",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="./secondPartCss/max.css" scoped></style>
<style src="./secondPartCss/middle.css" scoped></style>
<style src="./secondPartCss/flat.css" scoped></style>
<style src="./secondPartCss/min.css" scoped></style>
<style scoped></style>
