<!--
 * @FileDescription: 
 * @Author: jianguo Wang
 * @Date: 2023-12-14 15:29:31
 * @LastEditors: jianguo Wang
 * @LastEditTime: 2024-03-14 08:57:22
-->
<template>
  <div class="background-contain">
    <div class="header-block"></div>
    <div class="home-page">
      <!-- 头部 -->
      <div class="header">
        <img src="@/assets/Unoo-logo@3x.png" alt="" />
        <div class="header-btn-contain">
          <!-- <div class="header-btn1" @click="toUrl(1)">登录</div> -->
          <!-- <div class="header-btn2" @click="toUrl(2)">注册</div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "headerPart",
  props: {
    msg: String,
  },
  data() {
    return {
      toUrl(type) {
        if (type == 1) {
          location.href = "https://platform.hkyoucard.com/ ";
        } else {
          location.href = "https://platform.hkyoucard.com/#/register";
        }
      },
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="./headerCss/max.css" scoped></style>
<style src="./headerCss/middle.css" scoped></style>
<style src="./headerCss/flat.css" scoped></style>
<style src="./headerCss/min.css" scoped></style>
<style scoped></style>
