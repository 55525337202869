<!--
 * @FileDescription: 
 * @Author: jianguo Wang
 * @Date: 2023-12-14 15:29:31
 * @LastEditors: jianguo Wang
 * @LastEditTime: 2024-08-08 10:35:40
-->
<template>
  <div class="background-contain">
    <div class="page-contain">
      <div class="body">
        <div class="right-contain">
          <div class="right-contain-tip1">CregisPay</div>
          <div class="right-contain-tip1">让<span>支付</span>一触即达</div>
          <div class="right-contain-tip2">无论你在世界的哪个角落，无论你持有哪种法币或加密货币，支付未如此简单</div>

          <!-- 移动端显示图片 -->
          <img class="phone-right-img" src="@/assets/img2@3x.png" alt="" />
          <div class="phone-down-contain">
            <div slot="reference" class="right-contain-tip3" @click="toDownload(2)">
              <img v-if="acitve == 1" src="@/assets/ios-icon02.png" class="btn-icon" alt="" />
              <img v-else src="@/assets/ios-icon01.png" class="btn-icon" alt="" />
              <!-- 敬请期待 -->
              <div class="ios-btn">
                <div class="ios-title1">Download on the</div>
                <div class="ios-title2">App Store</div>
              </div>
            </div>
            <div slot="reference" class="right-contain-tip4 and-btn" @click="toDownload(1)">
              <!-- <img v-if="acitve == 1" src="@/assets/anz-icon01.png" class="btn-icon" alt="" />
              <img v-else src="@/assets/anz-icon02.png" class="btn-icon" alt="" />
              Android下载 -->
              <img src="../assets/goo@2x.png" alt="" />
              <div class="btn-text">
                <div class="ios-tip01">GET IT ON</div>
                <div class="ios-tip02">GOOGLE PLAY</div>
                <!-- <div class="ios-tip02">敬请期待</div> -->
              </div>
            </div>
            <div slot="reference" class="right-contain-tip4 and-btn" @click="toDownload(3)">
              <!-- 敬请期待 -->
              <div class="and-btn">
                <img style="width: 24px; height: 24px" src="../assets/down.png" alt="" />
                <div class="btn-text">安装APK</div>
              </div>
            </div>
          </div>

          <div class="down-contain">
            <el-popover placement="bottom" title="" trigger="hover" content="" popper-class="poppers" @show="acitve = 1" :disabled="env != 'production'">
              <div slot="reference" class="right-contain-tip3" :class="acitve != 1 ? 'hover1' : ''" @click="toDownload(2)">
                <img v-if="acitve == 1" src="@/assets/ios-icon02.png" class="btn-icon" alt="" />
                <img v-else src="@/assets/ios-icon01.png" class="btn-icon" alt="" />
                <!-- 敬请期待 -->
                <div class="ios-btn">
                  <div class="ios-title1">Download on the</div>
                  <div class="ios-title2">App Store</div>
                </div>
              </div>
              <img style="width: 150px; height: 150px" src="@/assets/code-ios.png" alt="" />
            </el-popover>
            <el-popover placement="bottom" title="" trigger="hover" content="" @show="acitve = 2" popper-class="poppers" :disabled="env != 'production'">
              <div slot="reference" class="right-contain-tip4" :class="acitve == 2 ? 'hover2' : ''" @click="toDownload(1)">
                <img src="../assets/goo@2x.png" class="btn-icon" style="width: 24px; height: 24px" alt="" />
                <div class="ios-btn">
                  <div class="ios-tip01">GET IT ON</div>
                  <div class="ios-tip02">GOOGLE PLAY</div>
                </div>
              </div>
              <img style="width: 150px; height: 150px" src="@/assets/code-android.png" alt="" />
            </el-popover>
            <el-popover placement="bottom" title="" trigger="hover" content="" @show="acitve = 3" popper-class="poppers" :disabled="env != 'production'">
              <div slot="reference" class="right-contain-tip4" style="margin-left: 16px" :class="acitve == 3 ? 'hover2' : ''" @click="toDownload(3)">
                <!-- <img v-if="acitve == 1" src="@/assets/anz-icon01.png" class="btn-icon" alt="" />
                <img v-else src="@/assets/anz-icon02.png" class="btn-icon" alt="" /> -->
                <img v-if="acitve == 3" src="../assets/down.png" class="btn-icon" alt="" />
                <img v-else src="../assets/down2.png" class="btn-icon" alt="" />
                安装APK
              </div>
              <img style="width: 150px; height: 150px" src="@/assets/apk.png" alt="" />
            </el-popover>
          </div>
        </div>
        <img class="right-img" src="@/assets/img2@3x.png" alt="" />
      </div>
      <div class="footer">
        <div class="footer-title">在所有接受 Visa/Master卡的地方购物</div>
        <div class="img-contain">
          <img class="pc-img" src="@/assets/icons.jpg" alt="" />
          <img class="phone-img" src="@/assets/pnone-icons.jpg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "firstPart",
  props: {
    msg: String,
  },
  data() {
    return {
      acitve: 1,
      env: process.env.NODE_ENV,
    };
  },
  methods: {
    toDownload(type) {
      // console.log(process.env.VUE_APP_DOWNLOAD_URl);
      console.log(process.env);
      // console.log(type);
      if (type == 1) {
        // 谷歌
        if (process.env.NODE_ENV == "production") location.href = "https://play.google.com/store/apps/details?id=com.bytelink.cregispay.card";
      } else if (type == 2) {
        // 苹果
        if (process.env.NODE_ENV == "production") location.href = "https://apps.apple.com/hk/app/cregispay/id6572298569";
      } else {
        if (process.env.NODE_ENV == "production") {
          location.href = "https://file.cregispay.org/app/cregispay.apk";
        } else {
          location.href = "https://file.cregispay.top/app/CregisPay.apk";
          console.log("测试环境https://file.cregispay.top/app/CregisPay.apk");
        }
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.el-popover .el-popper .poppers {
  min-width: 135px !important;
  width: 135px !important;
  padding: 0;
}
</style>
<style src="./firstPartCss/max.css" scoped></style>
<style src="./firstPartCss/middle.css" scoped></style>
<style src="./firstPartCss/flat.css" scoped></style>
<style src="./firstPartCss/min.css" scoped></style>
